import React from 'react';
import LayoutEN from '../components/layout.en';
import Seo from '../components/seo.en';
import SolutionsBlockEN from '../components/solutionsblock.component.en';
import Benefit from '../components/benefit.component';
import BottomCTA from '../components/bottomCTA.component.en';

import '../styles/solution.scss';

import Zoom from 'react-medium-image-zoom';
import '../styles/imagezoom.css';

export default function SolutionWorkflow() {
	return (
		<LayoutEN>
			<Seo title='Workflows' />
			<section id='introSolution'>
				<div className='solutionBg solution-workflow'>
					<div id='introSolution-text'>
						<h1>Workflows</h1>
						<p>
							Because it's not the same having processes than{' '}
							<strong>working by processes</strong>.
						</p>
						<p>(Although the little piece of paper with the ISO looks fancy)</p>
					</div>
				</div>
			</section>
			<section id='benefitsSolution'>
				<Benefit posImage='R' imageUrl='/images/kuflow_orquesta.png'>
					<h2>KuFlow in four steps</h2>
					<p>KuFlow basically keeps everyone's hands busy:</p>
					<ol>
						<li>
							<strong>You code the workflow</strong> with the process that
							already exists in your organization.
						</li>
						<li>
							If something can be automated, you <strong>automate it</strong>.
						</li>
						<li>
							<strong>You publish your process</strong> and now everybody in the
							team can execute it.
						</li>
						<li>
							The <strong>manager can see all processes' status</strong> and
							stay one step ahead of the team in terms of planification.
						</li>
					</ol>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_processdetails_01_EN.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Process details'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_workflow_advanced.png'>
					<h2>Advanced workflows, advanced tools</h2>
					<p>
						Tasks, variables, API calls and webhooks here{' '}
						<strong>are coded, not painted</strong>.
					</p>
					<p>
						If you want to make a diagram connecting everything with arrows and
						boxes or make integrations that work most of the time, go look for
						another tool.
					</p>
					<p>
						<strong>The advantages of coding</strong>, you say? When you have a
						flow of 22 little boxes and "something" is not working... You better
						start praying to debug.
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_code01.png'
							width='280'
							alt='Workflow code'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_workflow_sti.png'>
					<h2>ITS (Integrated Stubbornness System)</h2>
					<p>
						Imagine that an important task depends on connecting to a client's
						server, which performance... <i>Is so so</i>.
					</p>
					<p>
						<strong>
							If this were a half-baked application, you would get an email
						</strong>{' '}
						saying something like "Sorry, something went wrong".
					</p>
					<p>
						But KuFlow has an
						<strong> ITS (Integrated Stubbornness System)</strong> that will
						keep it trying as many times as you tell it until it's done.
					</p>
					<p>
						If you're interested in how it works,{' '}
						<a href='https://docs.kuflow.com/developers/architecture'>
							in the docs
						</a>{' '}
						you can see more about KuFlow's architecture.
					</p>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_workflow_devs.png'>
					<h2>From devs to devs</h2>
					<p>
						It's not that we like to be indispensable, but if it's about
						integrating systems,
						<strong>Who's going to do it better than us?</strong>
					</p>

					<p>
						If you want to load a hundred plugins on a WordPress site go ahead.
					</p>

					<p>
						But if we're talking about a{' '}
						<strong>
							25-step process with 4 integrations and 3 team roles....
						</strong>
						It's better that it goes through expert hands, especially if you are
						going modify the process every now and then.
					</p>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_complexity.png'>
					<h2>Your business doesn't need any more complexity</h2>
					<p>
						When someone new comes to the company, after a month they are still
						learning those "little tricks", corner cases and "special" clients.
					</p>
					<p>
						Face it, <strong>your business niche is already complex</strong>, it
						is the very nature of organizations. Simplify and solve problems.
					</p>
					<p>
						<strong>
							KuFlow makes sure that you can focus on what you're paid to do
						</strong>{' '}
						and forget about all of those specifics.
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_formdetails_01_EN.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Form details'
						/>
					</Zoom>
				</Benefit>
			</section>
			<SolutionsBlockEN />
			<BottomCTA />
		</LayoutEN>
	);
}
