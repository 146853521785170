import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import CTAButton from './ctabutton.component';
import '../styles/bottomCTA.scss';

export default function BottomCTA() {
	return (
		<div
			className='bCTA_columns'
			data-sal='flip-up'
			data-sal-delay='200'
			data-sal-easing='cubic-bezier(0.25, 0.1, 0, 2.05)'
		>
			<div className='bCTA_column bCTA_text'>
				<h2>Shall we?</h2>
				<p>
					<strong>
						In 30 minutes you'll have your first process up and running
					</strong>
					, tasting the true power of an hyperautomated company.
				</p>
				<p>
					It may be possible that{' '}
					<strong>you need some help to start up</strong>. If that's the case,
					you should know that we have a heads and hands lending service 😉.
				</p>

				<CTAButton color='lblue'>SIGN UP FREE</CTAButton>
			</div>
			<div className='bCTA_column bCTA_image'>
				<StaticImage
					src='../static/images/kuflow_mano_servicios.png'
					alt='Te echamos una mano'
					height={250}
				/>
			</div>
		</div>
	);
}
